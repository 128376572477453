
    <template>
      <section class="content element-doc">
        <h2>ColorPicker 颜色选择器</h2>
<p>用于颜色选择，支持多种格式。</p>
<h3>基础用法</h3>
<demo-block>
        <div><p>使用 v-model 与 Vue 实例中的一个变量进行双向绑定，绑定的变量需要是字符串类型。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;有默认值&lt;/span&gt;
    &lt;el-color-picker v-model=&quot;color1&quot;&gt;&lt;/el-color-picker&gt;
  &lt;/div&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;无默认值&lt;/span&gt;
    &lt;el-color-picker v-model=&quot;color2&quot;&gt;&lt;/el-color-picker&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const color1 = ref('#409EFF')
      const color2 = ref(null)
      return { color1, color2 }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>选择透明度</h3>
<demo-block>
        <div><p>ColorPicker 支持普通颜色，也支持带 Alpha 通道的颜色，通过<code>show-alpha</code>属性即可控制是否支持透明度的选择。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-color-picker v-model=&quot;color&quot; show-alpha&gt;&lt;/el-color-picker&gt;
&lt;/template&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const color = ref('rgba(19, 206, 102, 0.8)')
      return {
        color
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>预定义颜色</h3>
<demo-block>
        <div><p>ColorPicker 支持预定义颜色</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-color-picker v-model=&quot;color&quot; show-alpha :predefine=&quot;predefineColors&quot;&gt;
  &lt;/el-color-picker&gt;
&lt;/template&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const color = ref('rgba(255, 69, 0, 0.68)')
      const predefineColors = ref([
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ])
      return {
        color,
        predefineColors
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>不同尺寸</h3>
<demo-block>
        
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-color-picker v-model=&quot;color&quot;&gt;&lt;/el-color-picker&gt;
  &lt;el-color-picker v-model=&quot;color&quot; size=&quot;medium&quot;&gt;&lt;/el-color-picker&gt;
  &lt;el-color-picker v-model=&quot;color&quot; size=&quot;small&quot;&gt;&lt;/el-color-picker&gt;
  &lt;el-color-picker v-model=&quot;color&quot; size=&quot;mini&quot;&gt;&lt;/el-color-picker&gt;
&lt;/template&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const color = ref('#409EFF')
      return {
        color
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>modelValue / v-model</td>
<td>绑定值</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>size</td>
<td>尺寸</td>
<td>string</td>
<td>—</td>
<td>medium / small / mini</td>
</tr>
<tr>
<td>show-alpha</td>
<td>是否支持透明度选择</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>color-format</td>
<td>写入 v-model 的颜色的格式</td>
<td>string</td>
<td>hsl / hsv / hex / rgb</td>
<td>hex（show-alpha 为 false）/ rgb（show-alpha 为 true）</td>
</tr>
<tr>
<td>popper-class</td>
<td>ColorPicker 下拉框的类名</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>predefine</td>
<td>预定义颜色</td>
<td>array</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>change</td>
<td>当绑定值变化时触发</td>
<td>当前值</td>
</tr>
<tr>
<td>active-change</td>
<td>面板中当前显示的颜色发生改变时触发</td>
<td>当前显示的颜色值</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_color_picker = _resolveComponent("el-color-picker")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "有默认值"),
      _createVNode(_component_el_color_picker, {
        modelValue: _ctx.color1,
        "onUpdate:modelValue": $event => (_ctx.color1 = $event)
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "无默认值"),
      _createVNode(_component_el_color_picker, {
        modelValue: _ctx.color2,
        "onUpdate:modelValue": $event => (_ctx.color2 = $event)
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const color1 = ref('#409EFF')
      const color2 = ref(null)
      return { color1, color2 }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_color_picker = _resolveComponent("el-color-picker")

  return (_openBlock(), _createBlock(_component_el_color_picker, {
    modelValue: _ctx.color,
    "onUpdate:modelValue": $event => (_ctx.color = $event),
    "show-alpha": ""
  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const color = ref('rgba(19, 206, 102, 0.8)')
      return {
        color
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_color_picker = _resolveComponent("el-color-picker")

  return (_openBlock(), _createBlock(_component_el_color_picker, {
    modelValue: _ctx.color,
    "onUpdate:modelValue": $event => (_ctx.color = $event),
    "show-alpha": "",
    predefine: _ctx.predefineColors
  }, null, 8, ["modelValue", "onUpdate:modelValue", "predefine"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const color = ref('rgba(255, 69, 0, 0.68)')
      const predefineColors = ref([
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ])
      return {
        color,
        predefineColors
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_color_picker = _resolveComponent("el-color-picker")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_color_picker, {
      modelValue: _ctx.color,
      "onUpdate:modelValue": $event => (_ctx.color = $event)
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_color_picker, {
      modelValue: _ctx.color,
      "onUpdate:modelValue": $event => (_ctx.color = $event),
      size: "medium"
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_color_picker, {
      modelValue: _ctx.color,
      "onUpdate:modelValue": $event => (_ctx.color = $event),
      size: "small"
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_color_picker, {
      modelValue: _ctx.color,
      "onUpdate:modelValue": $event => (_ctx.color = $event),
      size: "mini"
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const color = ref('#409EFF')
      return {
        color
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  